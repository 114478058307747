import { pushToDataLayer } from "../data/data-layer.facade";

export type GAFeedbackLabel =
  | "ja_send"
  | "nei_send"
  | "ja-kommentar_send"
  | "nei-kommentar_send"
  | "ja_window_closed"
  | "nei_window_closed"
  | "back_to_question_1_yes"
  | "back_to_question_1_no";

export type GAFeedbackAction = "feedback_question_1" | "feedback_question_2";

type GAFeedbackFormName = "feedback_form" | "feedback_popup";

export type BaseGA4FeedbackEvent = {
  event: "formSubmission";
  form_type: "feedback";
  form_name: GAFeedbackFormName;
  form_step: GAFeedbackAction;
  form_answer: GAFeedbackLabel;
};

export const sendFeedbackAnalyticsEventForPopup = (label: GAFeedbackLabel, action: GAFeedbackAction): void => {
  const ga4Event: BaseGA4FeedbackEvent = {
    event: "formSubmission",
    form_type: "feedback",
    form_name: "feedback_popup",
    form_step: action,
    form_answer: label,
  };

  pushToDataLayer(ga4Event);
};

export const sendFeedbackAnalyticsEventForRibbon = (label: GAFeedbackLabel, action: GAFeedbackAction): void => {
  const ga4Event: BaseGA4FeedbackEvent = {
    event: "formSubmission",
    form_type: "feedback",
    form_name: "feedback_form",
    form_step: action,
    form_answer: label,
  };

  pushToDataLayer(ga4Event);
};
