﻿import { type ComponentChildren, type FunctionalComponent, h } from "preact";
import { useState } from "preact/hooks";
import { IceFeedbackForm, IceFeedbackPopupFormSuccess } from "./components/form";
import { IceFeedbackPrompt } from "./components/prompt";
import { IceFeedbackFormSteps } from "../Shared/feedback.models";

type IceFeedbackRibbonProps = {
  children: ComponentChildren;
  style: string;
};

export const IceFeedbackRibbon: FunctionalComponent<IceFeedbackRibbonProps> = ({
  style: styleClass,
}: IceFeedbackRibbonProps) => {
  const [feedbackFormStep, setFeedbackFormStep] = useState<IceFeedbackFormSteps>(IceFeedbackFormSteps.prompt);
  const [userFeedbackPositive, setUserFeedbackPositive] = useState<boolean | undefined>(undefined);
  const [publicId, setPublicId] = useState<string>("");
  const renderContent = () => {
    switch (feedbackFormStep) {
      case IceFeedbackFormSteps.prompt:
        return (
          <IceFeedbackPrompt
            feedbackFormStep={feedbackFormStep}
            setFeedbackFormStep={setFeedbackFormStep}
            setUserFeedbackPositive={setUserFeedbackPositive}
            userFeedbackPositive={userFeedbackPositive}
            setPublicId={setPublicId}
          />
        );
      case IceFeedbackFormSteps.details:
        return (
          <IceFeedbackForm
            userOpinionState={userFeedbackPositive}
            feedbackFormStep={feedbackFormStep}
            setFeedbackFormStep={setFeedbackFormStep}
            publicId={publicId}
          />
        );
      case IceFeedbackFormSteps.success:
        return <IceFeedbackPopupFormSuccess />;
      default:
        return null;
    }
  };
  const additionalStyleClasses = styleClass !== undefined ? `ice-feedback--${styleClass?.toLowerCase()}` : "";

  return (
    <div className={`ice-feedback ${additionalStyleClasses}`}>
      <div className="ice-feedback__content">
        <div className="container">{renderContent()}</div>
      </div>
    </div>
  );
};
