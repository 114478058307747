import { createRef, type FunctionComponent, h, type RefObject } from "preact";
import { IceFeedbackFormSteps } from "../../Shared/feedback.models";
import { IceSvgByName } from "../../Ribbon/components/form";
import { useEffect, useRef } from "preact/hooks";
import { sendFeedbackAnalyticsEventForPopup } from "@shared/Analytics/content/feedback-analytics";
import { setFeedbackCookieSubmitted } from "../../Shared/ice-feedback-cookie-utilities";
import { useKeyDown } from "@shared/Hooks/use-key-down-hook";

interface IceFeedbackHeaderPopupProps {
  feedbackFormStep: IceFeedbackFormSteps;
  setShowComponent: (showComponent: boolean) => void;
  setFeedbackFormStep: (step: IceFeedbackFormSteps) => void;
  userFeedbackPositive?: boolean;
  feedbackDialogRef: RefObject<HTMLDivElement>;
  headerAccessibilityId: string;
}

export const IceFeedbackPopupHeader: FunctionComponent<IceFeedbackHeaderPopupProps> = ({
  feedbackFormStep,
  setShowComponent,
  setFeedbackFormStep,
  userFeedbackPositive,
  headerAccessibilityId,
}: IceFeedbackHeaderPopupProps) => {
  const closeButtonRef = createRef<HTMLButtonElement>();
  const documentRef = useRef<Document>(document);

  const totalFormQuestions = Object.keys(IceFeedbackFormSteps).length / 2 - 1;
  const stepProgressText =
    feedbackFormStep !== IceFeedbackFormSteps.success
      ? `Spørsmål ${feedbackFormStep} av ${totalFormQuestions}`
      : "Vår favorittkunde har talt";

  const handleCloseFeedbackClick = () => {
    setShowComponent(false);
    setFeedbackCookieSubmitted(true); // feedback popup should not be visible again after closing it
    if (feedbackFormStep === IceFeedbackFormSteps.details) {
      sendFeedbackAnalyticsEventForPopup(
        userFeedbackPositive ? "ja_window_closed" : "nei_window_closed",
        "feedback_question_2",
      );
    }

    setFeedbackFormStep(IceFeedbackFormSteps.prompt);
  };
  useKeyDown("Escape", handleCloseFeedbackClick, documentRef);

  useEffect(() => {
    if (feedbackFormStep === IceFeedbackFormSteps.success && closeButtonRef?.current) {
      closeButtonRef.current.focus();
    }
  }, [closeButtonRef, feedbackFormStep]);

  return (
    <div className="ice-feedback__header">
      <div role="status" id={headerAccessibilityId}>
        {stepProgressText}
      </div>
      <button
        type="button"
        className="btn--feedback__popup btn--feedback__close"
        onClick={() => handleCloseFeedbackClick()}
        aria-label="Lukk tilbakemeldingsboks"
        ref={closeButtonRef}
      >
        <IceSvgByName name={"ice-close"} />
      </button>
    </div>
  );
};
