import { type FunctionalComponent, Fragment, h } from "preact";
import type { IceFeedbackFormSteps } from "../../Shared/feedback.models";
import { IceSvgByName } from "../../Ribbon/components/form";
import { Dispatch, type StateUpdater, useId, useState } from "preact/hooks";
import { sendFeedbackAnalyticsEventForPopup } from "@shared/Analytics/content/feedback-analytics";
import { IceButtonWithLoading } from "@shared/Components/IceButtons/IceButtonWithLoading";
import clsx from "clsx";
import { sendFeedbackRating } from "../../Shared/feedback.service";

interface IceFeedbackPopupPromptProps {
  feedbackFormStep: IceFeedbackFormSteps;
  setFeedbackFormStep: (setFeedbackStep: number) => void;
  setUserFeedbackPositive: (userFeedbackPositive: boolean) => void;
  userFeedbackPositive: boolean | undefined;
  setPublicId: Dispatch<StateUpdater<string>>;
  headerAccessibilityId: string;
}

export const IceFeedbackPopupPrompt: FunctionalComponent<IceFeedbackPopupPromptProps> = ({
  userFeedbackPositive,
  setUserFeedbackPositive,
  feedbackFormStep,
  setFeedbackFormStep,
  setPublicId,
  headerAccessibilityId,
}: IceFeedbackPopupPromptProps) => {
  const [formSubmitLoading, setFormSubmitLoading] = useState<boolean>(false);
  const [formSubmitError, setFormSubmitError] = useState<boolean>(false);
  const [formState, setFormState] = useState<"initial" | "yes submitted" | "no submitted" | "error">("initial");

  const handleSubmit = async (e: Event) => {
    e.preventDefault();

    setFormState(userFeedbackPositive ? "yes submitted" : "no submitted");

    setFormSubmitError(false);

    try {
      setFormSubmitLoading(true);
      const response = await sendFeedbackRating("IceNo_Popup", userFeedbackPositive);
      const data = (await response.json()) as { publicId: string };

      if (response.ok) {
        setPublicId(data.publicId);
        setFeedbackFormStep(feedbackFormStep + 1);
        sendFeedbackAnalyticsEventForPopup(userFeedbackPositive ? "ja_send" : "nei_send", "feedback_question_1");

        setFormState("initial");
      } else {
        setFormSubmitError(true);
        setFormState("error");
      }
    } catch (error) {
      console.error(error);
      setFormSubmitError(true);
      setFormState("error");
    } finally {
      setFormSubmitLoading(false);
    }
  };

  const contentId = useId();

  return (
    <Fragment>
      <div className="ice-feedback__title ice-feedback__title--text-center" id={contentId} tabIndex={-1}>
        Gjør ice det enkelt å finne riktig abonnement?
      </div>
      <form
        className={`ice-feedback-form ${formSubmitLoading ? "ice-feedback-form--loading" : ""}`}
        onClick={handleSubmit}
      >
        <div className="ice-feedback-form--block">
          <IceButtonWithLoading
            type="button"
            className="btn--feedback"
            aria-DescribedBy={`${headerAccessibilityId} ${contentId}`}
            initFocus={true}
            /*
                          FocusTimeout is set to be longer than the timeout in shopping-cart-event-handler.tsx.
                          setFocusAndResetState set timeout to 50 to ensure that the caller button is enabeled.
                        */
            focusTimeout={60}
            btnState={formState === "yes submitted" ? "loading" : formState === "no submitted" ? "disabled" : "initial"}
            onClick={() => {
              setUserFeedbackPositive(true);
            }}
          >
            <div className={clsx("btn-content", formState === "yes submitted" ? "visibility-hidden" : "")}>
              <IceSvgByName name={"ice-thumbs-up"} />
              Ja
            </div>
          </IceButtonWithLoading>

          <IceButtonWithLoading
            type="button"
            className="btn--feedback"
            onClick={() => {
              setUserFeedbackPositive(false);
            }}
            btnState={formState === "no submitted" ? "loading" : formState === "yes submitted" ? "disabled" : "initial"}
          >
            <div className={clsx("btn-content", formState === "no submitted" ? "visibility-hidden" : "")}>
              <IceSvgByName name={"ice-thumbs-down"} />
              Nei
            </div>
          </IceButtonWithLoading>
        </div>
        <div className="ice-feedback-form__error" aria-label="feilmelding" aria-live="polite">
          {formSubmitError && <p>Feil ved sending, prøv igjen senere</p>}
        </div>
      </form>
    </Fragment>
  );
};
