import { type FunctionalComponent, h } from "preact";
import { useId, useRef, useState } from "preact/hooks";
import { IceFeedbackFormSteps } from "../Shared/feedback.models";
import { IceFeedbackPopupContent } from "./components/content";
import { IceFeedbackPopupHeader } from "./components/header";
import { shouldFormShowBasedOnFeedbackCookie } from "../Shared/ice-feedback-cookie-utilities";
import { useFocusTrap } from "@shared/Hooks/focus-trap-hook";
import { toBoolean } from "@shared/Utils/common-utils";

interface IceFeedbackPopupProps {
  show: string;
}

export const IceFeedbackPopup: FunctionalComponent<IceFeedbackPopupProps> = ({ show }: IceFeedbackPopupProps) => {
  const [showComponent, setShowComponent] = useState<boolean>(shouldFormShowBasedOnFeedbackCookie() && toBoolean(show));
  const [userFeedbackPositive, setUserFeedbackPositive] = useState<boolean | undefined>(undefined);
  const [feedbackFormStep, setFeedbackFormStep] = useState<IceFeedbackFormSteps>(IceFeedbackFormSteps.prompt);

  const headerAccessibilityId = useId();
  const feedbackDialogRef = useRef<HTMLDivElement>(null);
  const focusTrapRef = useFocusTrap<HTMLDivElement>();

  if (!showComponent) return null;

  return (
    <div className={"ice-feedback ice-feedback--popup"} aria-atomic="true" role="dialog" ref={focusTrapRef}>
      <IceFeedbackPopupHeader
        feedbackFormStep={feedbackFormStep}
        setShowComponent={setShowComponent}
        setFeedbackFormStep={setFeedbackFormStep}
        userFeedbackPositive={userFeedbackPositive}
        feedbackDialogRef={feedbackDialogRef}
        headerAccessibilityId={headerAccessibilityId}
      />
      <IceFeedbackPopupContent
        feedbackFormStep={feedbackFormStep}
        setFeedbackFormStep={setFeedbackFormStep}
        setUserFeedbackPositive={setUserFeedbackPositive}
        userFeedbackPositive={userFeedbackPositive}
        headerAccessibilityId={headerAccessibilityId}
      />
    </div>
  );
};
