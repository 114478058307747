import { detectBrowser } from "@shared/Utils/client-info-utils";
import { getStateFromFeedbackCookie } from "./ice-feedback-cookie-utilities";
import { getAuthenticatedUserId } from "./ice-feedback-storage-utilities";

export type FeedbackType = "IceNo_Ribbon" | "IceNo_Popup";

export interface CreateFeedbackRequest {
  text?: string;
  liked?: boolean;
  browser?: string;
  clientVersion?: string;
  userAgent?: string;
  appInsightsUserId?: string;
  userId?: string;
  feedbackType?: FeedbackType;
  orderStep?: string;
  pageUrl?: string;
  rating?: number;
}

export interface UpdateFeedbackRequest {
  publicId: string;
  text?: string;
}

export async function sendFeedbackRating(feedbackType: FeedbackType, liked?: boolean) {
  const feedbackUrl = "/api/feedback";
  const body = createFeedbackRequest(liked, feedbackType);

  return fetch(feedbackUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(body),
  });
}

export async function sendFeedbackText(publicId: string, feedbackFormText: string) {
  const feedbackUrl = `/api/feedback/${publicId}`;
  const body: UpdateFeedbackRequest = {
    publicId,
    text: feedbackFormText,
  };

  return fetch(feedbackUrl, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(body),
  });
}

function createFeedbackRequest(liked: boolean | undefined, feedbackType: FeedbackType) {
  const clientInfo = detectBrowser(navigator.userAgent);
  const userId = getAuthenticatedUserId();
  const feedbackCookieState = getStateFromFeedbackCookie();
  const pageUrl = window.location.pathname.split("?")[0];
  const orderStep = feedbackCookieState?.orderStepState || "Ikke påbegynt";

  const request: CreateFeedbackRequest = {
    liked,
    browser: clientInfo.name,
    userAgent: navigator.userAgent,
    clientVersion: clientInfo.version,
    feedbackType,
    orderStep,
    userId: userId || undefined,
    pageUrl: pageUrl,
    rating: liked === undefined ? undefined : liked ? 1 : 0,
  };

  return request;
}
